import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
  Flex,
  Icon,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Radio,
  RadioGroup,
  HStack,
  Select,
} from "@chakra-ui/react";
import { FiUpload, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";
import { Test, Question } from "../../types/test";
import * as pdfjsLib from "pdfjs-dist";
import {
  pdfParsingModels,
  ParsingModelKey,
} from "../../utils/parsing-patterns";

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

interface TestPdfImporterModalProps {
  isOpen: boolean;
  onClose: () => void;
  onImport: (tests: Test[]) => void;
}

const TestPdfImporterModal: React.FC<TestPdfImporterModalProps> = ({
  isOpen,
  onClose,
  onImport,
}) => {
  const toast = useToast();
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [extractedTest, setExtractedTest] = useState<Test | null>(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [parsingModel, setParsingModel] = useState<ParsingModelKey>("ModelA");

  useEffect(() => {
    if (!isOpen) {
      resetState();
    }
  }, [isOpen]);

  const resetState = () => {
    setFile(null);
    setExtractedTest(null);
    setCurrentQuestionIndex(0);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.[0] || null;
    setFile(uploadedFile);
    if (uploadedFile) {
      await extractTestFromPdf(uploadedFile);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
    if (droppedFile) {
      await extractTestFromPdf(droppedFile);
    }
  };

  const extractTestFromPdf = async (pdfFile: File) => {
    try {
      const arrayBuffer = await pdfFile.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
      let fullText = "";

      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        let lastY,
          text = "";

        for (const item of textContent.items) {
          if ("transform" in item) {
            if (lastY != item.transform[5] && text.length > 0) {
              text += "\n";
            }
            text += item.str;
            lastY = item.transform[5];
          }
        }

        fullText += text + "\n\n"; // Add extra newline between pages
      }

      console.log("Extracted full text:", fullText);

      const questions = parseQuestions(fullText);

      console.log("Number of parsed questions:", questions.length);
      console.log("First parsed question:", questions[0]);

      if (questions.length === 0) {
        throw new Error("No questions found in the PDF");
      }

      const newTest: Test = {
        id: uuidv4(),
        title: "Test importado desde PDF",
        scenario: false,
        description: "",
        allowSkipQuestions: false,
        repeatIncorrectQuestions: false,
        enableTimer: false,
        questions: questions,
        stats: {
          attempts: 0,
          averageScore: 0,
          bestScore: 0,
          totalQuestions: questions.length,
        },
        isShared: false, // Add this line
        authorName: "", // Add this line
        questionCount: questions.length, // Add this line
      };

      console.log("New test object:", newTest);

      setExtractedTest(newTest);
    } catch (error) {
      console.error("Error extracting test from PDF:", error);
      toast({
        title: "Error al extraer el test del PDF",
        description: error instanceof Error ? error.message : "Unknown error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const parseQuestions = (text: string): Question[] => {
    const cleanText = text.replace(
      /^\d{4}\s*-\s*GACE-L\s*Página\s*\d+\s*de\s*\d+$/gm,
      ""
    );
    const questions: Question[] = [];
    const regex = pdfParsingModels[parsingModel].regex;

    let match;
    while ((match = regex.exec(cleanText)) !== null) {
      const [questionText, optionA, optionB, optionC, optionD] = match;

      const question: Question = {
        id: uuidv4(),
        question: questionText.trim(),
        options: [
          { option: optionA.slice(3).trim(), isCorrect: false },
          { option: optionB.slice(3).trim(), isCorrect: false },
          { option: optionC.slice(3).trim(), isCorrect: false },
          { option: optionD.slice(3).trim(), isCorrect: false },
        ],
      };

      questions.push(question);
    }

    return questions;
  };

  const renderQuestionEditor = () => {
    if (!extractedTest || extractedTest.questions.length === 0) return null;

    const currentQuestion = extractedTest.questions[currentQuestionIndex];

    return (
      <VStack spacing={6} align="stretch">
        <Text fontWeight="bold" fontSize="lg">
          Pregunta {currentQuestionIndex + 1} de{" "}
          {extractedTest.questions.length}
        </Text>
        <FormControl>
          <FormLabel>Enunciado</FormLabel>
          <Textarea
            value={currentQuestion.question}
            onChange={(e) =>
              updateQuestion({ ...currentQuestion, question: e.target.value })
            }
            rows={3}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Respuestas</FormLabel>
          <RadioGroup
            value={currentQuestion.options
              .findIndex((opt) => opt.isCorrect)
              .toString()}
            onChange={(value) => {
              const newOptions = currentQuestion.options.map((opt, index) => ({
                ...opt,
                isCorrect: index.toString() === value,
              }));
              updateQuestion({ ...currentQuestion, options: newOptions });
            }}
          >
            <VStack align="stretch" spacing={3}>
              {currentQuestion.options.map((option, index) => (
                <HStack key={index} alignItems="flex-start">
                  <Radio value={index.toString()} mt={3} />
                  <Input
                    value={option.option}
                    onChange={(e) => {
                      const newOptions = [...currentQuestion.options];
                      newOptions[index] = { ...option, option: e.target.value };
                      updateQuestion({
                        ...currentQuestion,
                        options: newOptions,
                      });
                    }}
                    flex={1}
                  />
                </HStack>
              ))}
            </VStack>
          </RadioGroup>
        </FormControl>
        <Flex p={4} mb={2} justify="space-around" width="100%" mt={0}>
          <Button
            onClick={() =>
              setCurrentQuestionIndex(Math.max(0, currentQuestionIndex - 1))
            }
            leftIcon={<Icon as={FiChevronLeft} />}
            colorScheme="blue"
            size="sm"
          >
            Anterior
          </Button>
          <Button
            onClick={deleteCurrentQuestion}
            colorScheme="red"
            variant="outline"
            size="sm"
          >
            Borrar Pregunta
          </Button>
          <Button
            onClick={() =>
              setCurrentQuestionIndex(
                Math.min(
                  extractedTest.questions.length - 1,
                  currentQuestionIndex + 1
                )
              )
            }
            rightIcon={<Icon as={FiChevronRight} />}
            colorScheme="blue"
            size="sm"
          >
            Siguiente
          </Button>
        </Flex>
      </VStack>
    );
  };

  const deleteCurrentQuestion = () => {
    if (extractedTest) {
      const updatedQuestions = extractedTest.questions.filter(
        (_, index) => index !== currentQuestionIndex
      );
      setExtractedTest({ ...extractedTest, questions: updatedQuestions });
      setCurrentQuestionIndex(
        Math.min(currentQuestionIndex, updatedQuestions.length - 1)
      );
    }
  };

  const updateQuestion = (updatedQuestion: Question) => {
    if (extractedTest) {
      const updatedQuestions = [...extractedTest.questions];
      updatedQuestions[currentQuestionIndex] = updatedQuestion;
      setExtractedTest({ ...extractedTest, questions: updatedQuestions });
    }
  };

  const handleImport = () => {
    if (extractedTest) {
      // Asegúrate de que el test tenga el número correcto de preguntas
      const updatedTest = {
        ...extractedTest,
        questions: extractedTest.questions.filter((q) => q !== null),
      };
      onImport([updatedTest]);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxH="80vh" overflowY="auto">
        <ModalHeader>Importar Test desde PDF</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!file ? (
            <VStack spacing={4}>
              <Flex
                direction="column"
                align="center"
                justify="center"
                p={6}
                borderWidth={2}
                borderRadius="md"
                borderColor="gray.300"
                borderStyle="dashed"
                bg="gray.50"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={() => fileInputRef.current?.click()}
                cursor="pointer"
              >
                <Icon as={FiUpload} w={10} h={10} color="gray.400" mb={4} />
                <Text fontWeight="bold" mb={2}>
                  {file
                    ? (file as File).name
                    : "Arrastra y suelta tu archivo PDF aquí"}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  o haz clic para seleccionar
                </Text>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileUpload}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
              </Flex>
              <FormControl>
                <FormLabel>Modelo de extracción</FormLabel>
                <Select
                  value={parsingModel}
                  onChange={(e) =>
                    setParsingModel(e.target.value as ParsingModelKey)
                  }
                >
                  {Object.entries(pdfParsingModels).map(([key, model]) => (
                    <option key={key} value={key}>
                      {model.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </VStack>
          ) : (
            <VStack spacing={4} align="stretch">
              <FormControl>
                <FormLabel>Título del Test</FormLabel>
                <Input
                  placeholder="Introduce el título del test"
                  value={extractedTest?.title || ""}
                  onChange={(e) =>
                    setExtractedTest((prev) =>
                      prev ? { ...prev, title: e.target.value } : null
                    )
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Descripción</FormLabel>
                <Textarea
                  placeholder="Descripción opcional del test"
                  value={extractedTest?.description || ""}
                  onChange={(e) =>
                    setExtractedTest((prev) =>
                      prev ? { ...prev, description: e.target.value } : null
                    )
                  }
                />
              </FormControl>
              {extractedTest && extractedTest.questions.length > 0 ? (
                renderQuestionEditor()
              ) : (
                <Text>No se encontraron preguntas en el PDF importado.</Text>
              )}
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="teal"
            onClick={handleImport}
            disabled={!extractedTest}
          >
            Importar
          </Button>
          <Button
            variant="solid"
            colorScheme="gray"
            ml={3}
            onClick={() => {
              resetState();
              onClose();
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TestPdfImporterModal;
